<template>
  <div class="card card-custom card-transparent">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">1</div>
                <div class="wizard-label">
                  <div class="wizard-title">Penerapan Perizinan Berusaha</div>
                  <div class="wizard-desc">Penerapan Perizinan Berusaha</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">2</div>
                <div class="wizard-label">
                  <div class="wizard-title">
                    Penyederhanaan Perizinan Berusaha Sektor
                  </div>
                  <div class="wizard-desc">
                    Penyederhanaan Perizinan Berusaha Sektor
                  </div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">3</div>
                <div class="wizard-label">
                  <div class="wizard-title">Peningkatan Iklim Investasi</div>
                  <div class="wizard-desc">Peningkatan Iklim Investasi</div>
                </div>
              </div>
            </div>

            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-label">
                  <div class="wizard-title">Persetujuan Berita Acara</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-12 px-0 py-lg-15 px-lg-0">
              <div class="col-xl-12 col-xxl-11">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-10" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Penerapan Perizinan
                          Berusaha berdasarkan fakta yang ada dengan memilih
                          data yang sesuai. Dokumen lampiran yang diunggah
                          adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file
                          setiap dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Unggah</th>
                            <th style="display: none">Download</th>
                            <th style="display: none">Hapus</th>
                            <th style="display: none">Pilihan</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b
                                >Penyusunan NSPK terkait penerapan perizinan
                                berusaha</b
                              >
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada Konsultasi pengusulan NSPK dengan
                              Kementerian Terkait lainnya?
                            </td>
                            <td colspan="4" style="text-align: justify">
                              Untuk Kementerian Negara/ Lembaga (K/L) yang telah
                              memiliki NSPK, yang dimaksud dalam tolok ukur di
                              sini adalah konsultasi dalam rangka evaluasi atas
                              NSPK yang sudah ada. Termasuk jika ada KBLI yang
                              belum diatur/diampu di dalam PP No. 5 Tahun 2021.
                              Penilaian konsultasi pengusulan NSPK dengan
                              Kementerian terkait lainnya dilakukan dengan
                              ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p1a1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1a1_nilai',
                                      dataVerlap.p1a1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options1"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1a1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1a1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1a1_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  disabled
                                  v-model="dataQC.p1a1_nilai"
                                  @change="
                                    submitQC('p1a1_nilai', dataQC.p1a1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a1_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a1_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a1_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a1_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a1_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada harmonisasi pengusulan NSPK dengan
                              Kementerian Terkait lainnya?
                            </td>
                            <td colspan="4" style="text-align: justify">
                              Pilihan pada indikator dan tolok ukur ini
                              menyesuaikan dengan Pilihan pada indikator
                              Penyusunan NSPK terkait penerapan perizinan
                              berusaha. Penilaian harmonisasi pengusulan NSPK
                              dengan Kementerian terkait lainnya
                              (sekurang-kurangnya melibatkan Kementerian
                              Koordinator Bidang Perekonomian, Sekretariat
                              Kabinet, atau Kementerian Investasi/BKPM)
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p1a2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1a2_nilai',
                                      dataVerlap.p1a2_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options1"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1a2_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1a2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1a2_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  disabled
                                  v-model="dataQC.p1a2_nilai"
                                  @change="
                                    submitQC('p1a2_nilai', dataQC.p1a2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a2_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a2_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a2_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a2_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a2_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a2_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a2_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a2_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="3">3</td>
                            <td rowspan="3">
                              Apakah ada dokumen NSPK terkait penerapan
                              Perizinan Berusaha?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Peraturan Menteri/ Peraturan Kepala Badan
                                  tentang NSPK terkait perizinan berusaha
                                  berbasis risiko (jika sudah disahkan)
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a3_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a3_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>

                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options2"
                                  style="min-width: 150px"
                                  v-model="datapm.p1a3_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1a3_nilai',
                                      dataVerlap.p1a3_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options2"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1a3_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1a3_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1a3_nilai_status
                                    ),
                                  }"
                                  :options="options2"
                                  disabled
                                  v-model="dataQC.p1a3_nilai"
                                  @change="
                                    submitQC('p1a3_nilai', dataQC.p1a3_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Peraturan Menteri/ Peraturan Kepala Badan
                                  tentang NSPK terkait perizinan berusaha
                                  berbasis risiko (jika sudah disahkan)
                                </div>
                              </div>
                            </td>
                            <td>
                              <a :href="dataQC.p1a3_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a3_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penilaian ada tidaknya dokumen NSPK terkait
                                    penerapan Perizinan berusaha dilakukan
                                    dengan ketentuan tersedia dokumen NSPK
                                    terkait penerapan Perizinan berusaha dalam
                                    lembaran negara. Untuk penetapan bobot
                                    penilaian: <br />
                                    1. Nilai “Ada, sudah disahkan” apabila
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, belum disahkan” apabila
                                    ketentuan di atas tidak terpenuhi namun
                                    sudah tersedia dalam bentuk naskah/dokumen
                                    usulan. <br />
                                    3. Nilai “Belum ada” apabila ketentuan di
                                    atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">4</td>
                            <td rowspan="7">
                              Apakah ada kegiatan Sosialisasi NSPK Penerapan
                              Perizinan Berusaha?
                            </td>
                            <td colspan="4" style="text-align: justify">
                              Penilaian ada tidaknya Sosialisasi NSPK Penerapan
                              Perizinan Berusaha dilakukan dengan ketentuan:
                              sosialisasi dilakukan sekurang-kurangnya 1 kali
                              dalam 3 bulan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Sosialisasi dilakukan secara Nasional
                                  sekurang-kurangnya dilakukan di tingkat
                                  Provinsi
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a4_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a4_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p1a4_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1a4_nilai',
                                      dataVerlap.p1a4_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options1"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1a4_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1a4_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1a4_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  disabled
                                  v-model="dataQC.p1a4_nilai"
                                  @change="
                                    submitQC('p1a4_nilai', dataQC.p1a4_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digita
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a4_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a4_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a4_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a4_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a4_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a4_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1a4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1a4_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p1a4_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b
                                >Integrasi sistem Kementrian Negara/Lembaga
                                dengan sistem OSS</b
                              >
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="4">1</td>
                            <td rowspan="4">
                              Apakah memiliki sistem di K/L yang terintegrasi
                              dengan OSS atau menggunakan sistem OSS dengan hak
                              akses?
                            </td>
                            <td colspan="4" style="text-align: justify">
                              Penilaian integrasi sistem atau penggunaan hak
                              akses OSS oleh kementerian negara/lembaga
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Semua pemrosesan permohonan perizinan berusaha
                                  sektor berdasarkan lampiran PP No. 5 Tahun
                                  2021 yang menjadi kewenangannya menggunakan
                                  sistem K/L yang terintegrasi dengan OSS
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1b1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p1b1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options3"
                                  style="min-width: 150px"
                                  v-model="datapm.p1b1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1b1_nilai',
                                      dataVerlap.p1b1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options3"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1b1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1b1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1b1_nilai_status
                                    ),
                                  }"
                                  :options="options3"
                                  disabled
                                  v-model="dataQC.p1b1_nilai"
                                  @change="
                                    submitQC('p1b1_nilai', dataQC.p1b1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Semua pemrosesan permohonan perizinan berusaha
                                  sektor berdasarkan lampiran PP No. 5 Tahun
                                  2021 yang menjadi kewenangannya menggunakan
                                  sistem OSS dengan hak akses
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1b1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p1b1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Memiliki sistem dan Terintegrasi”
                                    apabila ketentuan nomor 1 terpenuhi.<br />
                                    2. Nilai “Tidak memiliki sistem dan
                                    menggunakan hak akses” apabila ketentuan
                                    nomor 2 terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="3">2</td>
                            <td rowspan="3">
                              Berapa persen tingkat verifikasi teknis dan
                              notifikasi pemenuhan standar dan persyaratan
                              Perizinan Berusaha Berbasis Risiko?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Dokumen bukti permohonan risiko MT yang masuk
                                  dan verifikasinya di sistem OSS
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1b2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p1b2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options4"
                                  style="min-width: 150px"
                                  v-model="datapm.p1b2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1b2_nilai',
                                      dataVerlap.p1b2_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options4"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1b2_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1b2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1b2_nilai_status
                                    ),
                                  }"
                                  :options="options4"
                                  disabled
                                  v-model="dataQC.p1b2_nilai"
                                  @change="
                                    submitQC('p1b2_nilai', dataQC.p1b2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1b2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Dokumen bukti permohonan risiko T yang masuk
                                  dan verifikasinya di sistem OSS
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1b2_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p1b2_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penilaian verifikasi teknis dan notifikasi
                                    pemenuhan standar dan persyaratan Perizinan
                                    Berusaha Berbasis Risiko dilakukan dengan
                                    ketentuan: <br />
                                    1. Verifikasi teknis dan notifikasi
                                    pemenuhan standar oleh K/L terhadap
                                    permohonan perizinan berusaha dengan tingkat
                                    risiko Menengah Tinggi (MT) yang menjadi
                                    kewenangannya.<br />
                                    2. Verifikasi teknis dan notifikasi
                                    pemenuhan persyaratan oleh K/L terhadap
                                    permohonan perizinan berusaha dengan tingkat
                                    risiko Tinggi (T) yang menjadi
                                    kewenangannya. <br />
                                    3. Penilaian verifikasi teknis dan
                                    notifikasi pemenuhan standar dan persyaratan
                                    Perizinan Berusaha Berbasis Risiko dilakukan
                                    dengan menghitung perbandingan jumlah
                                    permohonan perizinan berusaha dengan tingkat
                                    risiko MT dan T yang telah diverifikasi
                                    dengan total permohonan perizinan berusaha
                                    yang masuk ke dalam dashboard OSS K/L
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Monitoring dan Evaluasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada kegiatan monitoring dan evaluasi
                              implementasi Perizinan Berusaha?
                            </td>
                            <td colspan="4" style="text-align: justify">
                              Penilaian ada tidaknya kegiatan monitoring dan
                              evaluasi implementasi Perizinan Berusaha dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Kegiatan monitoring dan evaluasi dilakukan
                                  sekurang-kurangnya 1 kali dalam 1 semester
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1c1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p1c1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options5"
                                  style="min-width: 150px"
                                  v-model="datapm.p1c1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1c1_nilai',
                                      dataVerlap.p1c1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options5"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1c1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1c1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1c1_nilai_status
                                    ),
                                  }"
                                  :options="options5"
                                  disabled
                                  v-model="dataQC.p1c1_nilai"
                                  @change="
                                    submitQC('p1c1_nilai', dataQC.p1c1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1c1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1c1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p1c1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1c1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1c1_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p1c1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1c1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula Monev</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1c1_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p1c1_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1c1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1c1_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p1c1_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Apakah ada laporan kinerja implementasi Perizinan
                              Berusaha?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1c2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Penilaian ada tidaknya laporan kinerja
                                  implementasi perizinan berusaha dilakukan
                                  dengan ketentuan apabila laporan kinerja
                                  implementasi Perizinan Berusaha dibuat secara
                                  formal dalam bentuk dokumen laporan lengkap
                                  fisik atau digital, bukan bahan presentasi dan
                                  sejenisnya
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p1c2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p1c2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td>
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options6"
                                  style="min-width: 150px"
                                  v-model="datapm.p1c2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1c2_nilai',
                                      dataVerlap.p1c2_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options6"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1c2_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1c2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1c2_nilai_status
                                    ),
                                  }"
                                  :options="options6"
                                  disabled
                                  v-model="dataQC.p1c2_nilai"
                                  @change="
                                    submitQC('p1c2_nilai', dataQC.p1c2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <hr />

                    <div v-if="datapm.catatan_1_surveyor != null">
                      <b-card
                        border-variant="primary"
                        header-bg-variant="primary"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan Surveyor
                          </h5>
                        </template>
                        <div
                          class="card-text"
                          v-html="datapm.catatan_1_surveyor"
                        ></div>
                      </b-card>
                    </div>
                    <br />
                    <div v-if="datapm.catatan_1_bkpm != null">
                      <b-card
                        border-variant="danger"
                        header-bg-variant="danger"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan BKPM
                          </h5>
                        </template>
                        <div
                          class="card-text"
                          v-html="datapm.catatan_1_bkpm"
                        ></div>
                      </b-card>
                    </div>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Penyederhanaan
                          Perizinan Berusaha Sektor berdasarkan fakta yang ada
                          dengan memilih data yang sesuai. Dokumen lampiran yang
                          diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file
                          setiap dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Unggah</th>
                            <th style="display: none">Download</th>
                            <th style="display: none">Hapus</th>
                            <th style="display: none">Pilihan</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b
                                >Penapisan bidang usaha berdasarkan tingkat
                                risiko</b
                              >
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="3">1</td>
                            <td rowspan="3">
                              Apakah ada kegiatan penapisan bidang usaha
                              berdasarkan risiko?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1c2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  BA kegiatan penapisan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options7"
                                  style="min-width: 150px"
                                  v-model="datapm.p2a1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p2a1_nilai',
                                      dataVerlap.p2a1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options7"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p2a1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2a1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2a1_nilai_status
                                    ),
                                  }"
                                  :options="options7"
                                  disabled
                                  v-model="dataQC.p2a1_nilai"
                                  @change="
                                    submitQC('p2a1_nilai', dataQC.p2a1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p1c2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Surat Sekretaris Menteri Koordinator Bidang
                                  Perekonomian Nomor No.
                                  PI.01./433/SES.M.EKON/06/202
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-white">
                                  <p style="color: white">Informasi</p>
                                  Penilaian ada tidaknya kegiatan penapisan
                                  bidang usaha berdasarkan risiko dilakukan
                                  dengan ketentuan: <br />
                                  <table
                                    class="table table-borderless"
                                    style="
                                      color: white;
                                      background-color: #3699ff;
                                    "
                                  >
                                    <tr>
                                      <td>1.</td>
                                      <td>
                                        K/L melakukan penapisan pada semua KBLI
                                        yang disebutkan dalam PP No. 5 Tahun
                                        2021.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>2.</td>
                                      <td>
                                        K/L melakukan penapisan KBLI sesuai
                                        surat Sekretaris Menteri Koordinator
                                        Bidang Perekonomian Nomor No.
                                        PI.01./433/SES.M.EKON/06/2021 tanggal 16
                                        Juni 2021 perihal KBLI Beririsan dan
                                        KBLI Tanpa K/L Pengampu.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>3.</td>
                                      <td>
                                        K/L yang tidak memiliki kewajiban
                                        melakukan penapisan pada KLBI sesuai
                                        surat Sekretaris Menteri Koordinator
                                        Bidang Perekonomian Nomor No.
                                        PI.01./433/SES.M.EKON/06/2021 tanggal 16
                                        Juni 2021 perihal KBLI Beririsan dan
                                        KBLI Tanpa K/L Pengampu.
                                      </td>
                                    </tr>
                                  </table>

                                  Untuk penetapan bobot penilaian: <br />
                                  <table
                                    class="table table-borderless"
                                    style="
                                      color: white;
                                      background-color: #3699ff;
                                    "
                                  >
                                    <tr>
                                      <td width="20px">1.</td>
                                      <td colspan="2">
                                        Nilai "Ada, semua" apabila:
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td width="20px">a.</td>
                                      <td>
                                        ketentuan nomor 1 dan 2 terpenuhi.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td>b.</td>
                                      <td>
                                        ketentuan nomor 1 dan 3 terpenuhi.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20px">2.</td>
                                      <td colspan="2">
                                        Nilai "Ada, sebagian" apabila:
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td width="20px">a.</td>
                                      <td>
                                        K/L melakukan penapisan pada semua KBLI
                                        yang disebutkan dalam PP No. 5 Tahun
                                        2021. Namun, tidak melakukan penapisan
                                        terhadap semua KBLI sesuai surat
                                        Sekretaris Menteri Koordinator Bidang
                                        Perekonomian Nomor No.
                                        PI.01./433/SES.M.EKON/06/2021 tanggal 16
                                        Juni 2021 perihal KBLI Beririsan dan
                                        KBLI Tanpa K/L Pengampu, maupun
                                        sebaliknya;
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td>b.</td>
                                      <td>
                                        K/L melakukan penapisan pada sebagian
                                        KBLI yang disebutkan dalam PP No. 5
                                        Tahun 2021. Namun, telah melakukan
                                        penapisan pada semua KBLI sesuai surat
                                        Sekretaris Menteri Koordinator Bidang
                                        Perekonomian Nomor No.
                                        PI.01./433/SES.M.EKON/06/2021 tanggal 16
                                        Juni 2021 perihal KBLI Beririsan dan
                                        KBLI Tanpa K/L, maupun sebaliknya; atau
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td>c.</td>
                                      <td>
                                        K/L melakukan penapisan pada sebagian
                                        baik atas KBLI yang disebutkan dalam PP
                                        No. 5 Tahun 2021 maupun pada KBLI sesuai
                                        surat Sekretaris Menteri Koordinator
                                        Bidang Perekonomian Nomor No.
                                        PI.01./433/SES.M.EKON/06/2021 tanggal 16
                                        Juni 2021 perihal KBLI Beririsan dan
                                        KBLI Tanpa K/L.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20px">3.</td>
                                      <td colspan="2">
                                        Nilai “Tidak ada” apabila seluruh
                                        ketentuan di atas tidak terpenuhi.
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">2</td>
                            <td rowspan="4">
                              Apakah ada hasil penapisan bidang usaha
                              berdasarkan risiko?
                            </td>
                            <td colspan="5" style="text-align: justify">
                              Penilaian ada tidaknya hasil penapisan bidang
                              usaha berdasarkan risiko dilakukan dengan
                              ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Tolok ukur ini sesuai dengan penilaian pada
                                  tolok ukur menilai ada tidaknya kegiatan
                                  penapisan bidang usaha berdasarkan risiko dan
                                  wajib menunjukkan dokumen hasil penapisan pada
                                  seluruh KBLI yang menjadi kewenangannya.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options7"
                                  style="min-width: 150px"
                                  v-model="datapm.p2a2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p2a2_nilai',
                                      dataVerlap.p2a2_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options7"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p2a2_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2a2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2a2_nilai_status
                                    ),
                                  }"
                                  :options="options7"
                                  disabled
                                  v-model="dataQC.p2a2_nilai"
                                  @change="
                                    submitQC('p2a2_nilai', dataQC.p2a2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2a2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  K/L yang tidak memiliki kewajiban melakukan
                                  penapisan pada KBLI sesuai surat Sekretaris
                                  Menteri Koordinator Bidang Perekonomian Nomor
                                  No. PI.01./433/SES.M.EKON/06/2021 tanggal 16
                                  Juni 2021 perihal KBLI Beririsan dan KBLI
                                  Tanpa K/L Pengampu.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2a2_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2a2_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, semua” apabila ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Ada, sebagian” apabila salah satu
                                    ketentuan di atas tidak terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b
                                >Penyusunan NSPK berdasarkan penapisan bidang
                                usaha</b
                              >
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada konsultasi pengusulan NSPK dengan
                              Kementerian Terkait lainnya?
                            </td>
                            <td colspan="4" style="text-align: justify">
                              Untuk K/L yang telah memiliki NSPK, yang dimaksud
                              dalam tolok ukur di sini adalah konsultasi dalam
                              rangka evaluasi atas NSPK yang sudah ada. Termasuk
                              penapisan pada KBLI sesuai surat Sekretaris
                              Menteri Koordinator Bidang Perekonomian Nomor No.
                              PI.01./433/SES.M.EKON/06/2021 tanggal 16 Juni 2021
                              perihal KBLI Beririsan dan KBLI Tanpa K/L
                              Pengampu. Penilaian konsultasi pengusulan NSPK
                              dengan Kementerian terkait lainnya
                              (sekurang-kurangnya melibatkan Kementerian
                              Koordinator Bidang Perekonomian, Sekretariat
                              Kabinet, atau Kementerian Investasi/BKPM)
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 6 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p2b1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p2b1_nilai',
                                      dataVerlap.p2b1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options1"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p2b1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2b1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2b1_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  disabled
                                  v-model="dataQC.p2b1_nilai"
                                  @change="
                                    submitQC('p2b1_nilai', dataQC.p2b1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b1_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b1_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b1_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b1_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b1_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada harmonisasi pengusulan NSPK dengan
                              Kementerian Terkait lainnya?
                            </td>
                            <td colspan="4" style="text-align: justify">
                              Untuk K/L yang telah memiliki NSPK, yang dimaksud
                              dalam tolok ukur di sini adalah harmonisasi dalam
                              rangka Evaluasi atas NSPK yang sudah ada. Termasuk
                              penapisan pada KBLI sesuai surat Sekretaris
                              Menteri Koordinator Bidang Perekonomian Nomor No.
                              PI.01./433/SES.M.EKON/06/2021 tanggal 16 Juni 2021
                              perihal KBLI Beririsan dan KBLI Tanpa K/L
                              Pengampu. Penilaian harmonisasi pengusulan NSPK
                              dengan Kementerian terkait lainnya
                              (sekurang-kurangnya melibatkan Kementerian
                              Koordinator Bidang Perekonomian, Sekretariat
                              Kabinet, atau Kementerian Investasi/BKPM)
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Konsultasi dilakukan sekurang-kurangnya 1 kali
                                  dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p2b2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p2b2_nilai',
                                      dataVerlap.p2b2_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options1"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p2b2_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2b2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2b2_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  disabled
                                  v-model="dataQC.p2b2_nilai"
                                  @change="
                                    submitQC('p2b2_nilai', dataQC.p2b2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b2_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b2_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b2_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b2_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b2_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b2_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b2_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b2_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="3">3</td>
                            <td rowspan="3">
                              Apakah ada NSPK berdasarkan penapisan bidang
                              usaha?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Peraturan Menteri/ Peraturan Kepala Badan
                                  tentang NSPK bidang usaha
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b3_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b3_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options2"
                                  style="min-width: 150px"
                                  v-model="datapm.p2b3_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p2b3_nilai',
                                      dataVerlap.p2b3_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options2"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p2b3_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2b3_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2b3_nilai_status
                                    ),
                                  }"
                                  :options="options2"
                                  disabled
                                  v-model="dataQC.p2b3_nilai"
                                  @change="
                                    submitQC('p2b3_nilai', dataQC.p2b3_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Rancangan Peraturan Menteri/ Peraturan Kepala
                                  Badan tentang NSPK bidang usaha
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b3_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b3_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Penilaian ada tidaknya dokumen NSPK
                                    berdasarkan penapisan bidang usaha dilakukan
                                    dengan ketentuan tersedia dokumen NSPK
                                    berdasarkan penapisan bidang usaha dalam
                                    lembaran negara
                                  </p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, sudah disahkan” apabila salah
                                    satu ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, belum disahkan” apabila
                                    seluruh ketentuan di atas tidak terpenuhi
                                    namun sudah tersedia dalam bentuk
                                    naskah/dokumen usulan. <br />
                                    3. Nilai “Belum ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="8">4</td>
                            <td rowspan="8">
                              Apakah ada kegiatan sosialisasi NSPK penapisan
                              bidang usaha berdasarkan tingkat risiko?
                            </td>
                            <td colspan="4" style="text-align: justify">
                              Penilaian ada tidaknya kegiatan sosialisasi NSPK
                              penapisan bidang usaha berdasarkan tingkat risiko
                              dilakukan dengan ketentuan
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Sosialisasi dilakukan sekurang-kurangnya 1
                                  kali dalam 1 semester
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b4_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b4_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options8"
                                  style="min-width: 150px"
                                  v-model="datapm.p2b4_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p2b4_nilai',
                                      dataVerlap.p2b4_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options8"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p2b4_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2b4_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2b4_nilai_status
                                    ),
                                  }"
                                  :options="options8"
                                  disabled
                                  v-model="dataQC.p2b4_nilai"
                                  @change="
                                    submitQC('p2b4_nilai', dataQC.p2b4_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Sosialisasi dilakukan secara Nasional
                                  sekurang-kurangnya dilakukan di tingkat
                                  Provinsi
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b4_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b4_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b4_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b4_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b4_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b4_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b4_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b4_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2b4_file6 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2b4_file6" target="_blank">
                                <button
                                  v-if="this.dataQC.p2b4_file6 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, hanya 1 kali” apabila salah
                                    satu dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Monitoring dan Evaluasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada monitoring dan evaluasi implementasi
                              penapisan bidang usaha berdasarkan tingkat risiko?
                            </td>
                            <td colspan="4" style="text-align: justify">
                              Penilaian ada tidaknya monitoring dan evaluasi
                              implementasi penapisan bidang usaha berdasarkan
                              tingkat risiko dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Kegiatan monitoring dan evaluasi dilakukan
                                  sekurang-kurangnya 1 kali dalam 1 semester
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options5"
                                  style="min-width: 150px"
                                  v-model="datapm.p2c1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p2c1_nilai',
                                      dataVerlap.p2c1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options5"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p2c1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p2c1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p2c1_nilai_status
                                    ),
                                  }"
                                  :options="options5"
                                  disabled
                                  v-model="dataQC.p2c1_nilai"
                                  @change="
                                    submitQC('p2c1_nilai', dataQC.p2c1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c1_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula sosialisasi
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c1_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c1_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p2c1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p2c1_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p2c1_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>

                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <hr />

                    <div v-if="datapm.catatan_2_surveyor != null">
                      <b-card
                        border-variant="primary"
                        header-bg-variant="primary"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan Surveyor
                          </h5>
                        </template>
                        <div
                          class="card-text"
                          v-html="datapm.catatan_2_surveyor"
                        ></div>
                      </b-card>
                    </div>
                    <br />
                    <div v-if="datapm.catatan_2_bkpm != null">
                      <b-card
                        border-variant="danger"
                        header-bg-variant="danger"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan BKPM
                          </h5>
                        </template>
                        <div
                          class="card-text"
                          v-html="datapm.catatan_2_bkpm"
                        ></div>
                      </b-card>
                    </div>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Peningkatan Iklim
                          Investasi berdasarkan fakta yang ada dengan memilih
                          data yang sesuai. Dokumen lampiran yang diunggah
                          adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file
                          setiap dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <!-- <th style="display:none;">Status</th> -->
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Unggah</th>
                            <th style="display: none">Download</th>
                            <th style="display: none">Hapus</th>
                            <th style="display: none">Pilihan</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Unit Pelaksana PPB</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">1</td>
                            <td rowspan="4">
                              Apakah ada Organisasi Pelaksana PPB?
                            </td>
                            <td colspan="4">
                              Penilaian ada tidaknya Organisasi Pelaksana PPB
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  K/L memiliki dan dapat menunjukkan penetapan/
                                  keputusan dari Menteri/Kepala Lembaga
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options6"
                                  style="min-width: 150px"
                                  v-model="datapm.p3a1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p3a1_nilai',
                                      dataVerlap.p3a1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options6"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p3a1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3a1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3a1_nilai_status
                                    ),
                                  }"
                                  :options="options6"
                                  disabled
                                  v-model="dataQC.p3a1_nilai"
                                  @change="
                                    submitQC('p3a1_nilai', dataQC.p3a1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  K/L memiliki ruang sekretariat organisasi tim
                                  internal K/L yang melaksanakan tugas dan
                                  fungsi PPB
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila kedua ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="4">2</td>
                            <td rowspan="4">Apakah ada program kerja PPB?</td>
                            <td colspan="3">
                              Penilaian ada tidaknya program kerja PPB dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  K/L memiliki dan dapat menunjukkan bukti
                                  program kerja
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options6"
                                  style="min-width: 150px"
                                  v-model="datapm.p3a2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p3a2_nilai',
                                      dataVerlap.p3a2_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options6"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p3a2_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3a2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3a2_nilai_status
                                    ),
                                  }"
                                  :options="options6"
                                  disabled
                                  v-model="dataQC.p3a2_nilai"
                                  @change="
                                    submitQC('p3a2_nilai', dataQC.p3a2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  K/L memiliki dan dapat menunjukkan bukti
                                  realisasi program kerja (sekurang-kurangnya
                                  50% dari program kerja yang direncanakan)
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a2_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a2_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila kedua ketentuan di
                                    atas terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila salah satu
                                    ketentuan di atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada rapat koordinasi unit pelaksana PPB?
                            </td>
                            <td colspan="3">
                              Penilaian ada tidaknya rapat koordinasi teragenda
                              dilakukan dengan ketentuan
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 1 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a3_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a3_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options5"
                                  style="min-width: 150px"
                                  v-model="datapm.p3a3_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p3a3_nilai',
                                      dataVerlap.p3a3_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options5"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p3a3_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3a3_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3a3_nilai_status
                                    ),
                                  }"
                                  :options="options5"
                                  disabled
                                  v-model="dataQC.p3a3_nilai"
                                  @change="
                                    submitQC('p3a3_nilai', dataQC.p3a3_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a3_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a3_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a3_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a3_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a3_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a3_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3a3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3a3_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p3a3_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b
                                >Koordinasi Formal Lintas Pemangku
                                kepentingan</b
                              >
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada koordinasi formal dengan sesama
                              instansi Pusat?
                            </td>
                            <td colspan="4">
                              Penilaian ada tidaknya koordinasi formal dengan
                              sesama instansi Pusat dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 6 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p3b1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p3b1_nilai',
                                      dataVerlap.p3b1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options1"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p3b1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3b1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3b1_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  disabled
                                  v-model="dataQC.p3b1_nilai"
                                  @change="
                                    submitQC('p3b1_nilai', dataQC.p3b1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b1_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b1_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b1_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b1_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b1_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">2</td>
                            <td rowspan="7">
                              Apakah ada koordinasi formal dengan Pemerintah
                              Provinsi?
                            </td>
                            <td colspan="4">
                              Penilaian ada tidaknya koordinasi formal dengan
                              Provinsi dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p3b2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p3b2_nilai',
                                      dataVerlap.p3b2_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options1"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p3b2_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3b2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3b2_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  disabled
                                  v-model="dataQC.p3b2_nilai"
                                  @change="
                                    submitQC('p3b2_nilai', dataQC.p3b2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b2_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b2_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b2_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b2_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b2_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b2_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b2_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b2_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b2_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b2_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Apakah ada koordinasi formal dengan Pemerintah
                              Kab/Kota?
                            </td>
                            <td colspan="4">
                              Penilaian ada tidaknya koordinasi formal dengan
                              Pemda Kabupaten/Kota dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b3_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b3_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options1"
                                  style="min-width: 150px"
                                  v-model="datapm.p3b3_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p3b3_nilai',
                                      dataVerlap.p3b3_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options1"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p3b3_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3b3_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3b3_nilai_status
                                    ),
                                  }"
                                  :options="options1"
                                  disabled
                                  v-model="dataQC.p3b3_nilai"
                                  @change="
                                    submitQC('p3b3_nilai', dataQC.p3b3_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b3_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b3_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b3_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b3_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b3_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b3_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b3_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b3_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">4</td>
                            <td rowspan="7">
                              Apakah ada koordinasi pemangku kepentingan lainnya
                              (Perbankan dan APH)?
                            </td>
                            <td colspan="4">
                              Penilaian ada tidaknya koordinasi pemangku
                              kepentingan lainnya (Perbankan dan APH) dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b4_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b4_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options5"
                                  style="min-width: 150px"
                                  v-model="datapm.p3b4_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p3b4_nilai',
                                      dataVerlap.p3b4_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options5"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p3b4_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3b4_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3b4_nilai_status
                                    ),
                                  }"
                                  :options="options5"
                                  disabled
                                  v-model="dataQC.p3b4_nilai"
                                  @change="
                                    submitQC('p3b4_nilai', dataQC.p3b4_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b4_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b4_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b4_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b4_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b4_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b4_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3b4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3b4_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p3b4_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Monitoring dan Evaluasi</b>
                            </td>
                          </tr>
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada kegiatan monitoring dan evaluasi hasil
                              peningkatan iklim investasi?
                            </td>
                            <td colspan="4">
                              Penilaian ada tidaknya kegiatan monitoring dan
                              evaluasi hasil peningkatan iklim investasi
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Rapat koordinasi dilakukan sekurang-kurangnya
                                  1 kali dalam 3 bulan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3c1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3c1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options5"
                                  style="min-width: 150px"
                                  v-model="datapm.p3c1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p3c1_nilai',
                                      dataVerlap.p3c1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options5"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p3c1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3c1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3c1_nilai_status
                                    ),
                                  }"
                                  :options="options5"
                                  disabled
                                  v-model="dataQC.p3c1_nilai"
                                  @change="
                                    submitQC('p3c1_nilai', dataQC.p3c1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3c1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3c1_file2" target="_blank">
                                <button
                                  v-if="this.dataQC.p3c1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3c1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat daftar hadir</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3c1_file3" target="_blank">
                                <button
                                  v-if="this.dataQC.p3c1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3c1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">Terdapat notula rapat</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3c1_file4" target="_blank">
                                <button
                                  v-if="this.dataQC.p3c1_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3c1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3c1_file5" target="_blank">
                                <button
                                  v-if="this.dataQC.p3c1_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada, teragenda” apabila kelima
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Apakah ada laporan hasil monitoring peningkatan
                              iklim investasi?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3c2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Penilaian ada tidaknya laporan hasil
                                  monitoring peningkatan iklim investasi
                                  dilakukan dengan ketentuan apabila laporan
                                  hasil monitoring peningkatan iklim investasi
                                  dibuat secara formal dalam bentuk dokumen
                                  laporan lengkap fisik atau digital, bukan
                                  bahan presentasi dan sejenisnya
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3c2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3c2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td>
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options6"
                                  style="min-width: 150px"
                                  v-model="datapm.p3c2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p3c2_nilai',
                                      dataVerlap.p3c2_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options6"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p3c2_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3c2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3c2_nilai_status
                                    ),
                                  }"
                                  :options="options6"
                                  disabled
                                  v-model="dataQC.p3c2_nilai"
                                  @change="
                                    submitQC('p3c2_nilai', dataQC.p3c2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Tindak lanjut atas hasil Evaluasi</b>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="2">1</td>
                            <td rowspan="2">
                              Apakah ada penambahan alokasi anggaran (rutin)
                              pelayanan perizinan berusaha?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3d1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Apakah ada evaluasi hasil kinerja PPB tahun
                                  sebelumnya?
                                  <table>
                                    <tr>
                                      <td>1.</td>
                                      <td>
                                        Terdapat surat undangan resmi
                                        (melibatkan pihak eksternal) dalam
                                        bentuk fisik atau digital
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>2.</td>
                                      <td>
                                        Terdapat daftar hadir peserta rapat
                                        dalam bentuk fisik atau digital
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>3.</td>
                                      <td>Terdapat notula rapat</td>
                                    </tr>
                                    <tr>
                                      <td>4.</td>
                                      <td>
                                        Terdapat dokumentasi rapat dalam bentuk
                                        foto
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3d1_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3d1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td>
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options6"
                                  style="min-width: 150px"
                                  v-model="datapm.p3d1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p3d1_nilai',
                                      dataVerlap.p3d1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options6"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p3d1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3d1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3d1_nilai_status
                                    ),
                                  }"
                                  :options="options6"
                                  disabled
                                  v-model="dataQC.p3d1_nilai"
                                  @change="
                                    submitQC('p3d1_nilai', dataQC.p3d1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="2">2</td>
                            <td rowspan="2">
                              Apakah ada penambahan alokasi anggaran (rutin)
                              pelayanan perizinan berusaha?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataQC.p3d2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11" style="text-align: justify">
                                  Penilaian ada tidaknya penambahan alokasi
                                  anggaran (rutin) pelayanan perizinan berusaha
                                  dilakukan dengan ketentuan apabila ada
                                  penambahan alokasi anggaran (rutin) pelayanan
                                  perizinan berusaha.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataQC.p3d2_file1" target="_blank">
                                <button
                                  v-if="this.dataQC.p3d2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td>
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="options6"
                                  style="min-width: 150px"
                                  v-model="datapm.p3d2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p3d2_nilai',
                                      dataVerlap.p3d2_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="options6"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p3d2_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p3d2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p3d2_nilai_status
                                    ),
                                  }"
                                  :options="options6"
                                  disabled
                                  v-model="dataQC.p3d2_nilai"
                                  @change="
                                    submitQC('p3d2_nilai', dataQC.p3d2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card card-custom"
                              >
                                <div class="card-body text-green">
                                  <p style="color: white">Informasi</p>
                                  <p style="color: white">
                                    Untuk penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “Ada” apabila ketentuan di atas
                                    terpenuhi.<br />
                                    2. Nilai “Tidak ada” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <hr />

                    <div v-if="datapm.catatan_3_surveyor != null">
                      <b-card
                        border-variant="primary"
                        header-bg-variant="primary"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan Surveyor
                          </h5>
                        </template>
                        <div
                          class="card-text"
                          v-html="datapm.catatan_3_surveyor"
                        ></div>
                      </b-card>
                    </div>
                    <br />
                    <div v-if="datapm.catatan_3_bkpm != null">
                      <b-card
                        border-variant="danger"
                        header-bg-variant="danger"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan BKPM
                          </h5>
                        </template>
                        <div
                          class="card-text"
                          v-html="datapm.catatan_3_bkpm"
                        ></div>
                      </b-card>
                    </div>
                  </div>
                  <!--end: Wizard Step 3-->

                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    style="margin-top: -40px"
                  >
                    <h4 class="mb-10 font-weight-bold text-dark">
                      Persetujuan Berita Acara
                    </h4>

                    <div class="my-5">
                      <div
                        style="background-color: #f4a902"
                        class="card card-custom"
                      >
                        <div class="card-body text-green">
                          <h3
                            class="card-label text-green"
                            style="color: white"
                          >
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Persetujuan
                          </h3>
                          <hr />
                          <p style="color: white; font-size: 15px">
                            Kami dari pihak pendamping Quality Control (QC)
                            menyetujui hasil Verifikasi dan Validasi Akhir
                            Penilaian Mandiri yang telah dilaksanakan oleh tim
                            QC. Seluruh hasil Verifikasi dan Validasi Akhir
                            Penilaian Mandiri PPB K/L telah dicatat dan disimpan
                            di dalam Sistem Teknologi Informasi dan Komunikasi.
                          </p>
                          <div
                            v-if="
                              dataQC.file_ba == null || dataQC.file_ba == ''
                            "
                          >
                            <!-- profil.nama_kepala -->
                            <button
                              type="button"
                              style="
                                float: right;
                                background-color: #004d00;
                                border-color: #004d00;
                              "
                              @click="persetujuan"
                              v-if="user.id_role === 'pendamping_qc'"
                              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                            >
                              <i
                                class="fa fa-check"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Persetujuan
                            </button>
                          </div>
                          <div v-else>
                            <a
                              :href="dataQC.file_ba"
                              target="_blank"
                              class="btn btn-primary"
                              style="
                                float: right;
                                background-color: #004d00;
                                border-color: #004d00;
                              "
                              ><i
                                class="fa fa-download"
                                aria-hidden="true"
                              />Unduh File BA</a
                            >
                            <!-- <br><br><a style="float: right; color:white" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-repeat" style="color:white" aria-hidden="true"/> Ulangi</a> -->
                            <!-- <button v-if="profil.nama_kepala == null || profil.nama_kepala == ''" type="button" style="float: right; background-color: #004d00; border-color: #004d00; margin-right:10px;" @click="cekketua" class="btn btn-primary btn-sm font-weight-bolder text-uppercase px-9 py-4">Persetujuan</button>
                                    <button v-else-if="vf.nama_surveyor == null" type="button" style="float: right; background-color: #004d00; border-color: #004d00; margin-right:10px;" hidden class="btn btn-primary btn-sm font-weight-bolder text-uppercase px-9 py-4">Persetujuan</button>
                                    <button v-else type="button" style="float: right; background-color: #004d00; border-color: #004d00; margin-right:10px;" data-toggle="modal" data-target="#exampleModal" class="btn btn-primary btn-sm font-weight-bolder text-uppercase px-9 py-4">Persetujuan</button> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        Sebelumnya
                      </button>
                    </div>
                    <div>
                      <button
                        v-on:click="submit"
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-submit"
                      >
                        Selesai
                      </button>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                      >
                        Simpan Sementara
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>
  
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-verlapppb.scss";
</style>
  
  
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";

import axios from "axios";

import qs from "qs";

import VueLoading from "vuejs-loading-plugin";
import Vue from "vue";

Vue.use(VueLoading);

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: "Loading", // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: "rgb(255,255,255)", // set custom background
  classes: ["myclass"], // array, object or string
});

export default {
  name: "ppbkl-detail",
  mounted() {
    this.$forceUpdate();
    this.loadProfile();
    // this.$store.dispatch(SET_BREADCRUMB, [
    //   { title: localStorage.getItem("nama_wilayah"), route: "verifikasi" },
    //   { title: "Verifikasi PPB" },
    // ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    wizard.on("change", function (e) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
    this.loadPM();
    this.loadVerlap();
    this.loadQC();
    this.getlocation();
  },
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      id_ptsp: this.$route.params.id,
      tahun: new Date().getFullYear(),
      user: JSON.parse(localStorage.getItem("user")),
      bagian: [
        { value: "kadis", text: "Kepala Dinas" },
        { value: "kabidpb", text: "Kepala Bidang Perizinan Berusaha" },
        { value: "kasiepb", text: "Kepala Seksi Bidang Perizinan Berusaha" },
        {
          value: "foffpb",
          text: "Staf Front-office Bidang Perizinan Berusaha",
        },
        { value: "boffpb", text: "Staf Back-office Bidang Perizinan Berusaha" },
        { value: "hdeskpb", text: "Staf Help Desk Bidang Perizinan Berusaha" },
        { value: "kabiddal", text: "Kepala Bidang Dalaks" },
        { value: "kasiedal", text: "Kepala Seksi Bidang Dalaks" },
        { value: "foffdal", text: "Staf Front-office Bidang Dalaks" },
        { value: "boffdal", text: "Staf Back-office Bidang Dalaks" },
        { value: "hdeskdal", text: "Staf Help Desk Bidang Dalaks" },
      ],
      dataQC: {},
      optionsPilihan: [
        { value: "Belum QC", text: "Belum QC" },
        { value: "Sesuai", text: "Sesuai" },
        { value: "Tidak sesuai", text: "Tidak sesuai" },
      ],
      options1: [
        { value: 25, text: "Ada, lebih dari 1 kali" },
        { value: 10, text: "Ada 1 kali" },
        { value: 0, text: "Tidak ada" },
      ],
      options2: [
        { value: 25, text: "Ada, sudah disahkan" },
        { value: 10, text: "Ada, belum disahkan" },
        { value: 0, text: "Belum ada" },
      ],
      options3: [
        { value: 25, text: "Memiliki sistem dan Terintegrasi" },
        { value: 25, text: "Tidak memiliki sistem dan menggunakan hak akses" },
      ],
      options4: [
        { value: 25, text: "85 – 100%" },
        { value: 15, text: "70 – 84,99%" },
        { value: 10, text: "50 – 69,99%" },
        { value: 0, text: "dibawah 50%" },
      ],
      options5: [
        { value: 25, text: "Ada, teragenda" },
        { value: 10, text: "Ada, tidak teragenda" },
        { value: 0, text: "Tidak ada" },
      ],
      options6: [
        { value: 25, text: "Ada" },
        { value: 0, text: "Tidak ada" },
      ],
      options7: [
        { value: 25, text: "Ada, semua" },
        { value: 10, text: "Ada, sebagian" },
        { value: 0, text: "Tidak ada" },
      ],
      options8: [
        { value: 25, text: "Ada, lebih dari 1 kali" },
        { value: 10, text: "Ada, hanya 1 kali" },
        { value: 0, text: "Tidak ada" },
      ],
      location: null,
      gettingLocation: false,
      errorStr: null,
      latitude: "",
      longtitude: "",
      datapm: {},
      dataVerlap: {},
      dataQC: {},
      profil:{}
    };
  },
  methods: {
    warna(value) {
      if (value == "Sudah QC dengan perubahan") {
        return "blue";
      } else if (value == "Sudah QC") {
        return "green";
      } else {
        return "";
      }
    },
    warnaText(value) {
      if (value == "Sudah QC dengan perubahan") {
        return "white";
      } else if (value == "Sudah QC") {
        return "white";
      } else {
        return "black";
      }
    },
    persetujuan() {
      Swal.fire({
        title: "Perhatian",
        text: "Apakah Anda yakin ingin mempersetujui Berita Acara?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Setuju",
        cancelButtonText: "Tutup",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .put(
              this.url + "/qc_ppb_kl_2023_ba/" + this.dataQC.id,
              {
                _method: "patch",
              },
              {
                headers: {
                  xth: this.token,
                },
              }
            )
            .then((response) => {
              Swal.fire(
                "Berhasil",
                "Anda berhasil mempersetujui Berita Acara",
                "success"
              );
              this.loadQC();
              // localStorage.setItem('idpm', JSON.stringify(response.data.id))
              // console.log(response)
              return response;
            })
            .catch((error) => {
              Swal.fire(
                "Gagal",
                "Anda gagal mempersetujui Berita Acara",
                "danger"
              );
              // console.log(error)
              return error;
            });
        }
      });
    },
    loadProfile() {
      axios
        .get(
          this.url + `/profil_ppb_kls?filter=idkl,=,` + this.$route.params.id,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.profil = response.data.data;
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: this.profil.nama_kl },
            { title: "Quality Control" },
          ]);
        });
    },
    loadQC() {
      axios
        .get(
          this.url +
            "/qc_ppb_kl_2023?filter=idkl,=," +
            this.id_ptsp +
            ";tahun,=," +
            this.tahun,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.dataQC = response.data.data[0];
          console.log(this.dataQC);
        });
    },
    loadPM() {
      axios
        .get(
          this.url +
            "/pm_ppb_kl_2023?filter=idkl,=," +
            this.id_ptsp +
            ";tahun,=," +
            this.tahun,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.datapm = response.data.data[0];
        });
    },

    loadVerlap() {
      axios
        .get(
          this.url +
            "/verifikasi_ppb_kl_2023?filter=idkl,=," +
            this.id_ptsp +
            ";tahun,=," +
            this.tahun,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.dataVerlap = response.data.data[0];
          console.log(this.dataQC);
        });
    },

    submit: function (e) {
      e.preventDefault();
      this.$router.push("/qualitycontrol/ppb-kl");
    },

    onFileChanged(e, data) {
      console.log(data);
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);

        this.$bvToast.toast("File terlalu besar, pilih file maksimal 500KB", {
          title: `Failed `,
          variant: `danger`,
          solid: true,
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        let formData = new FormData();
        formData.append(data, this.selectedFile);
        axios
          .post(
            this.url + "/verifikasi_ppb_kl_2023/" + this.dataQC.id,
            formData,
            {
              // _method: 'patch',
              headers: {
                xth: this.token,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            this.$loading(false);
            this.$bvToast.toast(response.data.message, {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);
            this.loadVerlap();

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
      }
    },

    submitNilaiRadio(data, nilai) {
      let formData = new FormData();
      formData.append(data, nilai);
      console.log(data + nilai);
      axios
        .post(
          this.url + "/verifikasi_ppb_kl_2023/" + this.dataQC.id,
          formData,
          {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          // this.$loading(false);
          // this.$bvToast.toast('Berhasil Verifikasi', {
          //     title: `Success `,
          //     variant: `success`,
          //     solid: true
          // })
          // console.log(response);
          this.loadVerlap();

          return response;
        })
        .catch((error) => {
          // console.log(error)
          // this.$loading(false);
          // this.$bvToast.toast('Gagal Verifikasi', {
          //     title: `Failed `,
          //     variant: `danger`,
          //     solid: true
          // })
          return error;
        });
    },

    submitQC(data, value) {
      let formData = new FormData();
      formData.append(data, value);
      // axios
      //     .post(this.url + '/qc_ppb_kl_2023/' + this.dataQC.id, formData, {
      //         // _method: 'patch',
      //         headers: {
      //             'xth': this.token,
      //             'Content-Type': 'multipart/form-data'
      //         }
      //     })
      //     .then(response => {
      //         this.$loading(false);
      //         this.$bvToast.toast('Berhasil Quality Control', {
      //             title: `Success `,
      //             variant: `success`,
      //             solid: true
      //         })
      //         // console.log(response);
      //         this.loadQC()

      //         return response

      //     })
      //     .catch(error => {
      //         console.log(error)
      //         this.$loading(false);
      //         this.$bvToast.toast('Gagal Quality Control', {
      //             title: `Failed `,
      //             variant: `danger`,
      //             solid: true
      //         })
      //         return error
      //     })
    },

    hapusFile(data) {
      // let formData = new FormData();
      // formData.append('field', data);
      // formData.append('idptsp', this.dataQC.idptsp);
      // formData.append('tahun', this.tahun);
      console.log(this.dataQC.idkl);

      Swal.fire({
        title: "Apakah Anda yakin?",
        text: "Anda tidak bisa mengembalikan file ini",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus File",
      }).then((result) => {
        if (result.isConfirmed) {
          const formData = {
            field: data,
            idptsp: this.dataQC.idkl,
            tahun: this.tahun,
          };
          axios
            .put(
              this.url + "/verifikasi_ppb_kl_2023_delete_lampiran",
              qs.stringify(formData),
              {
                // _method: 'patch',
                headers: {
                  xth: this.token,
                  "content-type": "application/x-www-form-urlencoded",
                },
              }
            )
            .then((response) => {
              this.$loading(false);
              this.$bvToast.toast("Berhasil hapus file", {
                title: `Success `,
                variant: `success`,
                solid: true,
              });
              // console.log(response);
              this.loadVerlap();

              return response;
            })
            .catch((error) => {
              console.log(error);
              this.$loading(false);
              this.$bvToast.toast("Gagal hapus file", {
                title: `Failed `,
                variant: `danger`,
                solid: true,
              });
              return error;
            });
        }
      });
    },

    getlocation() {
      if (!("geolocation" in navigator)) {
        this.errorStr = "Geolocation is not available.";
        return;
      }

      this.gettingLocation = true;
      // get position
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.gettingLocation = false;
          this.location = pos;
          this.latitude = pos.coords.latitude;
          this.longtitude = pos.coords.longitude;
          console.log(this.latitude);
          console.log(this.longtitude);
        },
        (err) => {
          this.gettingLocation = false;
          this.errorStr = err.message;
        }
      );
    },
  },
};
</script>
  
<style scoped>
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 768px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin: 10px 1rem 1rem 1rem;
  }

  /* tr:nth-child(odd) {
      background: #E1EDFF;
    } */

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    /* padding-left: 50%; */
  }

  th {
    display: none;
  }

  /* td:before { */
  /* Now like a table header */
  /* position: absolute; */
  /* Top/left values mimic padding */
  /* top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap; */
  /* } */

  /*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
  /* td:nth-of-type(1):before { content: "No"; }
		td:nth-of-type(2):before { content: "Indikator"; }
		td:nth-of-type(3):before { content: "Status"; }
		td:nth-of-type(4):before { content: "Jawaban"; }
        td:nth-of-type(5):before { content: "Unggah"; } 
        td:nth-of-type(6):before { content: "Download"; }  */
}
</style>